<template>
    <manageLayout>
        <div class="p-inside-manage">
            <h1>Audience</h1>
            <button type="button" class="create_btn secondary_btn small" v-ripple @click="newList">New list</button>
            <div class="wrap_manage_table">
                <div class="top_bar">
                    <div class="tabs_section">
                        <ul>
                            <li :class="{'active': topFilter === 'all'}" @click="changeTopFilter('all')">All</li>
                            <li :class="{'active': topFilter === 'custom'}" @click="changeTopFilter('custom')">Custom</li>
                        </ul>
                    </div>
                    <div class="btnShowFilters"></div>
                </div>
                <followTable :tableData="FOLLOWERS" :data="'followers'"></followTable>
                <pagination
                    v-if="FOLLOWERS && FOLLOWERS.length && COUNT_FOLLOWERS > 10"
                    :count="COUNT_FOLLOWERS"
                    :currentPage="CURRENT_PAGE_FOLLOWERS"
                    :commit="'SET_CURRENT_PAGE_FOLLOWERS'">
                </pagination>
            </div>
        </div>
    </manageLayout>
</template>

<script>
import manageLayout from "@/layouts/manageLayout";
import followTable from "@/components/follow/followTable";
import {mapActions, mapGetters} from "vuex";
import pagination from "@/components/pagination";
export default {
    name: "followers",
    components: {
        manageLayout,
        followTable,
	    pagination
    },
	watch: {
		CURRENT_PAGE_FOLLOWERS() {
			this.getFollowers();
		}
	},
    computed: {
        ...mapGetters(['FOLLOWS_FILTER', 'FOLLOWERS',  'COUNT_FOLLOWERS', 'CURRENT_PAGE_FOLLOWERS']),
        ...mapGetters({
            topFilter: 'manageFollows/TOP_FILTER'
        }),
    },
    mounted() {
		if (!this.FOLLOWERS) {
			this.getFollowers();
		}
    },
    methods: {
        ...mapActions(['GET_FOLLOWERS']),
	    getFollowers() {
		    this.$store.commit('SET_FOLLOW_LOADER', true);
		    this.GET_FOLLOWERS()
			    .then(() => {
			    })
			    .catch(err => console.log(`GET_FOLLOWERS, ${err}`))
			    .finally(() =>  this.$store.commit('SET_FOLLOW_LOADER', false));
	    },
        newList() {

        },
        changeTopFilter(filter) {
            this.$store.commit('manageFollows/SET_TOP_FILTER', filter);
        }
    }
}
</script>

<style scoped>

</style>